import IMask from 'imask';

export default () => {
  const getMaskOptions = (input) => {
    let maskOptions = {};
    const maskUnit = input.getAttribute(`data-mask`);

    switch (maskUnit) {
      case `phone`:
        maskOptions = {
          mask: `+{7} 000 000 00 00`,
        };
        break;
      case `date`:
        maskOptions = {
          mask: `00.00.00[00]`
        };
        break;
      default:
        break;
    }

    return maskOptions;
  };

  const forms = document.querySelectorAll(`.form`);
  const inputErrorTemplate = document.querySelector(`#input-error`);
  const submitMessageTemplate = document.querySelector(`#submit-message`);
  const inputError = inputErrorTemplate ? inputErrorTemplate.content.querySelector(`.form__input-error`) : null;
  const submitMessage = submitMessageTemplate ? submitMessageTemplate.content.querySelector(`.form__submit-message`) : null;

  const initForm = (form) => {
    const inputs = form.querySelectorAll(`input:not([type="file"])`);
    const comboEl = form.querySelector(`.combobox`);
    const comboInput = comboEl.querySelector(`input[type="text"]`);
    const comboInputId = comboInput.getAttribute(`id`).replace(`-vacancy`, ``);
    const options = window.VACANCIES[comboInputId];
    const comboComponent = new window.Combobox(comboEl, options);

    comboComponent.init();

    comboInput.addEventListener(`blur`, (evt) => {
      if (evt.target.value) {
        removeError(evt.target);
      }
    });

    inputs.forEach((input) => {
      if (input.hasAttribute(`data-mask`)) {
        IMask(input, getMaskOptions(input));
      }
    });

    const clearForm = () => {
      while (form.firstChild) {
        form.removeChild(form.firstChild);
      }

      form.removeEventListener(`submit`, onFormSubmit);
      form.removeEventListener(`change`, onFormChange);
      form.removeEventListener(`invalid`, onFormInvalid);
    };

    const createSubmitMessage = (text, isError) => {
      const currentSubmitMessage = form.querySelector(`.form__submit-message`);

      if (currentSubmitMessage) {
        currentSubmitMessage.textContent = text;
      } else {
        const newMessage = submitMessage.cloneNode(true);

        if (isError) {
          newMessage.classList.add(`form__submit-message--error`);
        }

        newMessage.textContent = text;
        form.append(newMessage);
      }
    };

    const createError = (input, messageText) => {
      const inputParent = input.parentElement;
      const newError = inputError.cloneNode(true);
      const currentError = inputParent.querySelector(`.form__input-error`);

      if (currentError) {
        currentError.textContent = messageText;
      } else {
        newError.textContent = messageText;
        inputParent.classList.add(`form__input--invalid`);
        input.after(newError);
      }
    };

    const removeError = (input) => {
      const inputParent = input.parentElement;
      const currentError = inputParent.querySelector(`.form__input-error`);

      inputParent.classList.remove(`form__input--invalid`);

      if (currentError) {
        currentError.remove();
      }
    };

    const checkInput = (input) => {
      if (input.validity.valueMissing || !input.value) {
        createError(input, window.INPUT_ERROR_MESSAGES.required);
      } else if (!input.validity.valid) {
        createError(input, window.INPUT_ERROR_MESSAGES.invalid);
      } else if (input.validity.rangeUnderflow) {
        createError(input, window.INPUT_ERROR_MESSAGES.invalid);
      } else {
        removeError(input);
      }
    };

    const checkCombo = (combo) => {
      if (!combo.value) {
        createError(combo, window.INPUT_ERROR_MESSAGES.required);
      } else {
        removeError(combo);
      }
    };

    const onLoadSuccess = () => {
      clearForm();
      createSubmitMessage(window.SUBMIT_MESSAGE_SUCCESS);
    };

    const onLoadError = (errorText) => {
      createSubmitMessage(errorText, true);
    };

    const onFormSubmit = (evt) => {
      const data = new FormData(evt.target);

      evt.preventDefault();
      checkCombo(comboInput);

      if (!comboInput.value) {
        return;
      }

      window.load(onLoadSuccess, onLoadError, window.URL_UPLOAD, data);
    };

    const onFormInvalid = (evt) => {
      const invalidElement = evt.target.closest(`input:not([type="file"])`);

      evt.preventDefault();
      checkCombo(comboInput);
      checkInput(invalidElement);
    };

    const onFormChange = (evt) => {
      const changedElement = evt.target.closest(`input:not([type="file"])`);

      evt.preventDefault();
      checkInput(changedElement);
    };

    form.addEventListener(`submit`, onFormSubmit);
    form.addEventListener(`change`, onFormChange);
    form.addEventListener(`invalid`, onFormInvalid, true);
  };

  forms.forEach((form) => {
    initForm(form);
  });
};
