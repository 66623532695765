import GoogleMapsApi from './google-map-api';

export default () => {
  const mapElement = document.querySelector(`#map`);

  if (mapElement) {
    const gApiKey = `AIzaSyAOHzjK9PJnt76_lPcJ8uQWES7xQRd89qs`;
    const gmapApi = new GoogleMapsApi(gApiKey);

    gmapApi.load().then(() => {
      renderMap();
    });

    const ICON_BASE = `img/`;
    const ICON_FILE = `map-pin.svg`;
    const DATA_BASE = `data/`;
    const DATA_FILE = mapElement.getAttribute(`data-file`);

    let map;
    let bounds;
    const mapParams = {
      iconPath: ICON_BASE + ICON_FILE,
      dataPath: DATA_BASE + DATA_FILE,
      zoomDefault: 3,
      zoomMinimum: 2,
      zoomMaximum: 100
    };

    const maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
    const processPoints = (geometry, callback, argument) => {
      if (geometry instanceof google.maps.LatLng) {
        callback.call(argument, geometry);
      } else if (geometry instanceof google.maps.Data.Point) {
        callback.call(argument, geometry.get());
      } else {
        geometry.getArray().forEach((g) => {
          processPoints(g, callback, argument);
        });
      }
    };

    const renderMap = () => {
      map = new google.maps.Map(mapElement, {
        center: new google.maps.LatLng(0, 0),
        zoom: mapParams.zoomDefault,
        minZoom: mapParams.zoomMinimum,
        maxZoom: mapParams.zoomMaximum,
        restriction: {
          latLngBounds: {north: maxLat, south: -maxLat, west: -179.99, east: 179.99},
          strictBounds: true
        }
      });

      bounds = new google.maps.LatLngBounds();

      map.data.loadGeoJson(mapParams.dataPath);
      map.data.setStyle({
        icon: mapParams.iconPath
      });

      map.data.addListener(`addfeature`, (evt) => {
        processPoints(evt.feature.getGeometry(), bounds.extend, bounds);

        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 1, bounds.getNorthEast().lng() + 1);
          const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 1, bounds.getNorthEast().lng() - 1);
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }

        map.fitBounds(bounds);
      });

      window.addEventListener(`resize`, function () {
        if (map) {
          map.fitBounds(bounds);
        }
      });
    };
  }
};
