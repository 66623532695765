export default () => {
  const accordionElements = document.querySelectorAll(`.accordion`);

  const onAccordionButtonClick = (root) => {
    root.classList.toggle(`accordion--opened`);
    root.scrollIntoView({behavior: `smooth`, block: `nearest`});
  };

  accordionElements.forEach((accordion) => {
    const accordionButton = accordion.querySelector(`.accordion__button`);

    accordion.classList.remove(`accordion--opened`);
    accordionButton.addEventListener(`click`, () => {
      onAccordionButtonClick(accordion);
    });
  });
};
