import Swiper from '../../js/libs/swiper';

export default () => {

  const createSlider = (el, name, countSlides) => {
    const swiperEl = el.querySelector(`.` + name);

    const mainSwiper = new Swiper(swiperEl, {
      slidesPerView: countSlides,
      spaceBetween: 0,
      navigation: {
        nextEl: `.tabs-slider__link-next`,
        prevEl: `.tabs-slider__link-prev`,
      },
      pagination: {
        type: `fraction`
      },
    });
    return mainSwiper;
  };

  const createSliderTop = (el, name, countSlides) => {
    const swiperEl = el.querySelector(`.` + name);

    const mainSwiper = new Swiper(swiperEl, {
      slidesPerView: countSlides,
      spaceBetween: 0,
      centeredSlides: true,
      pagination: {
        type: `fraction`
      },
    });
    return mainSwiper;
  };

  function TabsSliderWidget(el, sliderInfo, selectedIndex) {
    if (!el) {
      return;
    }

    this.el = el;
    this.tabTriggers = this.el.getElementsByClassName(`tabs-slider__trigger-link`);
    this.tabPanels = this.el.getElementsByClassName(`tabs-slider__panel`);
    this.tabLeftButton = this.el.querySelector(`.tabs-slider__link-prev`);
    this.tabRightButton = this.el.querySelector(`.tabs-slider__link-next`);

    // this.titlePrefix = document.getElementById('title-prefix');
    this.sliderInfo = sliderInfo;

    if (this.tabTriggers.length === 0 || this.tabTriggers.length !== this.tabPanels.length) {
      return;
    }
    this._init(selectedIndex);
  }

  TabsSliderWidget.prototype._init = function (selectedIndex) {
    this.tabTriggersLength = this.tabTriggers.length;
    this.selectedTab = 0;
    this.prevSelectedTab = null;
    this.clickListener = this._clickEvent.bind(this);
    this.keydownListener = this._keydownEvent.bind(this);
    this.leftButtonOverListener = this._mouseOverPrevEvent.bind(this);
    this.leftButtonOutListener = this._mouseOutPrevEvent.bind(this);
    this.rightButtonOverListener = this._mouseOverNextEvent.bind(this);
    this.rightButtonOutListener = this._mouseOutNextEvent.bind(this);

    this.keys = {
      prev: 37,
      next: 39,
    };

    for (let i = 0; i < this.tabTriggersLength; i++) {
      this.tabTriggers[i].index = i;
      this.tabTriggers[i].addEventListener(`click`, this.clickListener, false);
      this.tabTriggers[i].addEventListener(`keydown`, this.keydownListener, false);

      if (this.tabTriggers[i].classList.contains(`is-selected`)) {
        this.selectedTab = i;
      }
    }
    this.tabLeftButton.addEventListener(`mouseover`, this.leftButtonOverListener);
    this.tabLeftButton.addEventListener(`mouseout`, this.leftButtonOutListener);
    this.tabRightButton.addEventListener(`mouseover`, this.rightButtonOverListener);
    this.tabRightButton.addEventListener(`mouseout`, this.rightButtonOutListener);

    if (!isNaN(selectedIndex)) {
      this.selectedTab = selectedIndex < this.tabTriggersLength ? selectedIndex : this.tabTriggersLength - 1;
    }

    this.selectTab(this.selectedTab);
    this.el.classList.add(`is-initialized`);

    if ((this.sliderInfo.visibleBreakpointEnd > document.body.clientWidth) && (this.sliderInfo.visibleBreakpointStart < document.body.clientWidth)) {
      this.slider = createSlider(this.el, this.sliderInfo.name, this.sliderInfo.countSlides);
      if (this.slider) {
        this.slider.on(`slideChange`, () => {
          let ii = this.slider.activeIndex;
          this.selectTab(ii, true);
          if (this.sliderTop) {
            this.sliderTop.slideTo(ii);
          }
        });
      }
    } else {
      this.slider = null;
    }

    if ((this.sliderInfo.visibleBreakpointEndTop > document.body.clientWidth) && (this.sliderInfo.visibleBreakpointStartTop <= document.body.clientWidth)) {
      this.sliderTop = createSliderTop(this.el, this.sliderInfo.nameTop, this.sliderInfo.countSlidesTop);
      let slider = this.sliderTop;
      let fun = function sliderDecoratorUpdateSlidesSize() {
        let min = 0;
        let max = slider.virtualSize - slider.width;
        slider.snapGrid.forEach((val, i, list) => {
          if (val < min) {
            list[i] = min;
          } else if (val > max) {
            list[i] = max;
          } else {
            list[i] = Math.round(val);
          }
        });
      };

      this.sliderTop.updateSlidesSize = fun;
      this.sliderTop.updateSlidesSize();
      this.sliderTop.slideTo(0);
    } else {
      this.sliderTop = null;
    }
  };

  TabsSliderWidget.prototype._clickEvent = function (evt) {
    evt.preventDefault();

    if (evt.target.index === this.selectedTab) {
      return;
    }

    this.selectTab(evt.target.index, true);
  };

  TabsSliderWidget.prototype._keydownEvent = function (evt) {
    let targetIndex;

    if (evt.keyCode === this.keys.prev || evt.keyCode === this.keys.next) {
      evt.preventDefault();
    } else {
      return;
    }

    if (evt.keyCode === this.keys.prev && evt.target.index > 0) {
      targetIndex = evt.target.index - 1;
    } else if (evt.keyCode === this.keys.next && evt.target.index < this.tabTriggersLength - 1) {
      targetIndex = evt.target.index + 1;
    } else {
      return;
    }

    this.selectTab(targetIndex, true);
  };

  TabsSliderWidget.prototype._mouseOverNextEvent = function () {
    this.el.classList.add(`tabs-slider--next-hover`);
  };

  TabsSliderWidget.prototype._mouseOverPrevEvent = function () {
    this.el.classList.add(`tabs-slider--prev-hover`);
  };

  TabsSliderWidget.prototype._mouseOutPrevEvent = function () {
    this.el.classList.remove(`tabs-slider--prev-hover`);

  };

  TabsSliderWidget.prototype._mouseOutNextEvent = function () {
    this.el.classList.remove(`tabs-slider--next-hover`);
  };

  TabsSliderWidget.prototype._show = function (index, userInvoked, indexOld) {
    this.tabTriggers[index].classList.add(`is-selected`);
    this.tabTriggers[index].setAttribute(`aria-selected`, true);
    this.tabTriggers[index].setAttribute(`tabindex`, 0);
    this.tabPanels[index].classList.remove(`is-hidden`);
    this.tabPanels[index].classList.add(`is-opened`);
    this.tabPanels[index].classList.remove(`is-hidden-right`);
    this.tabPanels[index].classList.remove(`is-hidden-left`);
    this.tabPanels[index].setAttribute(`aria-hidden`, false);

    if (index > indexOld) {
      this.tabPanels[index].classList.add(`is-opened-right`);
    } else {
      this.tabPanels[index].classList.add(`is-opened-left`);
    }
    if (this.slider) {
      this.slider.slideTo(index);
    }
  };

  TabsSliderWidget.prototype._hide = function (index, indexNew) {
    this.tabTriggers[index].classList.remove(`is-selected`);
    this.tabTriggers[index].setAttribute(`aria-selected`, false);
    this.tabTriggers[index].setAttribute(`tabindex`, -1);


    if (index > indexNew) {
      this.tabPanels[index].classList.add(`is-hidden-right`);
    } else {
      this.tabPanels[index].classList.add(`is-hidden-left`);
    }

    this.tabPanels[index].classList.remove(`is-opened`);
    this.tabPanels[index].classList.add(`is-hidden`);
    this.tabPanels[index].classList.remove(`is-opened-right`);
    this.tabPanels[index].classList.remove(`is-opened-left`);
    this.tabPanels[index].setAttribute(`aria-hidden`, true);

  };

  TabsSliderWidget.prototype.selectTab = function (index, userInvoked) {
    if (this.prevSelectedTab === null) {
      for (let i = 0; i < this.tabTriggersLength; i++) {
        if (i !== index) {
          this._hide(i, index);
        }
      }
    } else {
      this._hide(this.selectedTab, index);
    }

    this.prevSelectedTab = this.selectedTab;
    const indexOld = this.selectedTab;
    this.selectedTab = index;
    this._show(this.selectedTab, userInvoked, indexOld);
  };

  TabsSliderWidget.prototype.destroy = function () {
    for (let i = 0; i < this.tabTriggersLength; i++) {
      this.tabTriggers[i].classList.remove(`is-selected`);
      this.tabTriggers[i].removeAttribute(`aria-selected`);
      this.tabTriggers[i].removeAttribute(`tabindex`);

      this.tabPanels[i].classList.remove(`is-hidden`);
      this.tabPanels[i].removeAttribute(`aria-hidden`);
      this.tabPanels[i].removeAttribute(`tabindex`);
      this.tabTriggers[i].removeEventListener(`click`, this.clickListener, false);
      this.tabTriggers[i].removeEventListener(`keydown`, this.keydownListener, false);

      this.tabLeftButton.removeEventListener(`mouseover`, this.leftButtonOverListener);
      this.tabLeftButton.removeEventListener(`mouseout`, this.leftButtonOutListener);
      this.tabRightButton.removeEventListener(`mouseover`, this.rightButtonOverListener);
      this.tabRightButton.removeEventListener(`mouseout`, this.rightButtonOutListener);

      delete this.tabTriggers[i].index;
    }
    if (this.slider) {
      this.slider.destroy();
    }

    if (this.sliderTop) {
      this.sliderTop.destroy();
    }

    if (this.el) {
      this.el.classList.remove(`is-initialized`);
    }
  };

  const fleetSliderInfo = {
    name: `main-page__fleet-slider`,
    countSlides: 1,
    visibleBreakpointStart: 0,
    visibleBreakpointEnd: 768,
    nameTop: ``,
    countSlidesTop: 0,
    visibleBreakpointStartTop: 0,
    visibleBreakpointEndTop: 0,
  };

  const eventSliderInfo = {
    name: `tabs-slider__bottom`,
    countSlides: 1,
    visibleBreakpointStart: 0,
    visibleBreakpointEnd: Infinity,
    nameTop: `main-page__event-slider`,
    countSlidesTop: 5,
    visibleBreakpointStartTop: 768,
    visibleBreakpointEndTop: Infinity,
  };

  const projectsSliderInfo = {
    name: `projects__container`,
    countSlides: 1,
    visibleBreakpointStart: 0,
    visibleBreakpointEnd: 768,
    nameTop: ``,
    countSlidesTop: 0,
    visibleBreakpointStartTop: 0,
    visibleBreakpointEndTop: 0,
  };

  const eventsSlider = document.querySelector(`.tabs-slider--events`);

  const eventsSliderInfo = {
    name: `tabs-slider__bottom`,
    countSlides: 1,
    visibleBreakpointStart: 0,
    visibleBreakpointEnd: Infinity,
    nameTop: `events__tabs-slider`,
    countSlidesTop: 5,
    visibleBreakpointStartTop: 768,
    visibleBreakpointEndTop: Infinity,
  };

  if (eventsSlider) {
    let sliderEvents = new TabsSliderWidget(eventsSlider, eventsSliderInfo);
    window.addEventListener(`resize`, () => {
      sliderEvents.destroy();
      sliderEvents = new TabsSliderWidget(eventsSlider, eventsSliderInfo);
    });
  }

  const mainElement = document.querySelector(`.main-page`);
  if (!mainElement) {
    return;
  }

  let slider1 = new TabsSliderWidget(document.getElementsByClassName(`tabs-slider`)[0], fleetSliderInfo);
  let slider2 = new TabsSliderWidget(document.getElementsByClassName(`tabs-slider`)[1], eventSliderInfo);
  let slider3 = new TabsSliderWidget(document.getElementsByClassName(`tabs-slider`)[2], projectsSliderInfo);

  /*window.addEventListener(`resize`, () => {
    slider1.destroy();
    slider2.destroy();
    slider3.destroy();

    slider1 = new TabsSliderWidget(document.getElementsByClassName(`tabs-slider`)[0], fleetSliderInfo);
    slider2 = new TabsSliderWidget(document.getElementsByClassName(`tabs-slider`)[1], eventSliderInfo);
    slider3 = new TabsSliderWidget(document.getElementsByClassName(`tabs-slider`)[2], projectsSliderInfo);
  });*/
};
