import Swiper from '../../js/libs/swiper';

export default () => {
  let partnersSliderElement = document.querySelector(`.partners__slider`);
  if (!partnersSliderElement) {
    return;
  }

  let partnersScale = document.querySelector(`.partners__scale`);
  let partners = document.querySelector(`.partners`);
  
  window.addEventListener(`load`, ()=> {
    let partnersLinks = document.querySelectorAll(`.partners .partners__list-link`);
    partnersLinks.forEach((it) => {
      it.addEventListener('mouseenter', () => {
        partners.classList.add(`partners--hover`);
      });
      it.addEventListener('mouseleave', () => {
        partners.classList.remove(`partners--hover`);
      });
    });
  
  });

  
  const partnersSwiper = new Swiper(partnersSliderElement, {
    slidesPerView: 4.15,
    spaceBetween: 0,
    loop: true,
    allowTouchMove: false,
    breakpoints: {
      320: {
        slidesPerView: 2.4,
        loop: true,
      },
      768: {
        slidesPerView: 3,
        loop: true,
      },
      1024: {
        slidesPerView: 3.5,
        loop: true,
      },
      1300: {
        slidesPerView: 4.15,
        loop: true,
      },
    }
  });

  let numberSlides = partnersSwiper.slides.length;

  partnersScale.addEventListener(`input`, (evt) => {
    let value = evt.target.value;
    let sl = Math.round(value * numberSlides / 100);
    partnersSwiper.slideTo(sl);
  });
};
