export default () => {
  const inputFiles = document.querySelectorAll(`.input-file`);

  const oninputFileInputChange = (input, label) => {
    const file = input.files[0];
    const inputLabel = label;

    inputLabel.textContent = file.name;
    inputLabel.classList.add(`button--lowercase`);
  };

  inputFiles.forEach((inputFile) => {
    const inputFileLabel = inputFile.querySelector(`.button`);
    const inputFileField = inputFile.querySelector(`input[type="file"]`);

    inputFileField.addEventListener(`change`, (evt) => {
      oninputFileInputChange(evt.target, inputFileLabel);
    });
  });
};
