import WindRose from "./wind-rose";

export default () => {
  const windRosesNodes = document.querySelectorAll(`.js-wind-rose`);
  if (!windRosesNodes.length) {
    return;
  }

  window.windRosesList = []
  windRosesNodes.forEach((item) => {
    window.windRosesList.push(new WindRose(item));
  })
}
