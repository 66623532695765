import Swiper from '../../js/libs/swiper';

export default () => {
  const mainPageElement = document.querySelector(`.main-page`);
  const mainSliderElement = document.querySelector(`.template-page__img-container--main`);
  const mainSliderElementText = document.querySelector(`.intro__slider`);
  const nextButton = document.querySelector(`.template-page__next-screen-button`);

  if (!mainPageElement) {
    return;
  }

  nextButton.addEventListener(`mouseenter`, () => {
    const slideNext = document.querySelector(`.template-page__img-container .swiper-slide-next`);
    slideNext.classList.remove(`template-page__slide-next-hide`);
    slideNext.classList.add(`template-page__slide-next-visible`);
  });

  nextButton.addEventListener(`mouseleave`, () => {
    const slideNext = document.querySelector(`.template-page__img-container .swiper-slide-next`);
    slideNext.classList.remove(`template-page__slide-next-visible`);
    slideNext.classList.add(`template-page__slide-next-hide`);
  });

  nextButton.addEventListener(`click`, () => {
    const slidesPrev = document.querySelectorAll(`.template-page__img-container .swiper-slide`);
    slidesPrev.forEach((it) => {
      it.classList.remove(`template-page__slide-next-visible`);
      it.classList.remove(`template-page__slide-next-hide`);
      it.classList.remove(`template-page__slide-prev-visible`);
      it.classList.remove(`template-page__slide-prev-hide`);
    });
  });

  const mainLogo = document.querySelector(`.site-nav__logo`);

  const initSlides = () => {
    const mainSlides = document.querySelectorAll(`.intro__slide`);
    mainSlides.forEach((it) => {
      const mainScreenButtonToElement = it.querySelector(`.intro__top-button`);
      const mainScreenButtonReturnElement = it.querySelector(`.intro__button-return`);
      const mainScreenTabs = it.querySelectorAll(`.intro__tab`);

      mainScreenButtonToElement.addEventListener(`click`, () => {
        mainScreenTabs[0].classList.remove(`intro__tab--visible`);
        mainScreenTabs[1].classList.add(`intro__tab--visible`);
        mainPageElement.classList.add(`main-page--animation-backround`);
        mainPageElement.classList.add(`main-page--second-tab`);
      });

      mainScreenButtonReturnElement.addEventListener(`click`, () => {
        mainScreenTabs[0].classList.add(`intro__tab--visible`);
        mainScreenTabs[1].classList.remove(`intro__tab--visible`);
        mainPageElement.classList.remove(`main-page--animation-backround`);
        mainPageElement.classList.remove(`main-page--second-tab`);
      });
    });
  };

  let mainSwiper = null;
  let mainSwiperText = null;

  const initSlider = () => {
    if (mainSwiper) {
      mainSwiper.destroy();
    }

    if (mainSwiperText) {
      mainSwiperText.destroy();
    }

    mainSwiper = new Swiper(mainSliderElement, {
      slidesPerView: 1.001,
      spaceBetween: 0,
      loop: true,
      allowSlidePrev: false,
      navigation: {
        nextEl: `.template-page__next-screen-button`
      },
    });

    mainSwiperText = new Swiper(mainSliderElementText, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      allowSlidePrev: false,
      allowTouchMove: true,
      navigation: {
        nextEl: `.template-page__next-screen-button`
      },
    });

    /*mainSwiperText.on(`sliderMove`, () => {
    });*/

    mainSwiperText.on(`slideChange`, () => {
      mainPageElement.classList.remove(`main-page--animation-backround`);
      const currentSlideTabs = document.querySelectorAll(`.swiper-slide-active .intro__tab`);
      currentSlideTabs[0].classList.add(`intro__tab--visible`);
      currentSlideTabs[1].classList.remove(`intro__tab--visible`);
      mainPageElement.classList.remove(`main-page--second-tab`);
      mainSwiper.slideNext(mainSwiperText.speed);
      
      const nextSlideTabs = document.querySelectorAll(`.swiper-slide-next .intro__tab`);
      let ind = 0;
      nextSlideTabs.forEach((it, i) => {
        if (it.classList.contains(`intro__tab--visible`)) {
          ind = i;
        }
      });
      
      if (ind > 0) {
        mainPageElement.classList.add(`main-page--second-tab`);
      } else {
        mainPageElement.classList.remove(`main-page--second-tab`);
      }
    });

  };

  initSlider();
  window.addEventListener(`load`, initSlides);
};
