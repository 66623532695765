export default class WindRose {
  constructor(element) {
    this.element = element;
    this.canvasNode = this.element.querySelector(`canvas`);
    this.width = this.canvasNode.width / 2;
    this.height = this.canvasNode.height / 2;
    this.diagonal = Math.sqrt(this.width * this.width + this.width * this.width);

    this.ctx = null;
    this.coords = null;

    this.values = [];
    this.color = null;
    this.maxValue = 0;
    this.xStep = 0;
    this.yStep = 0;
    this.diagonalStep = 0;

    this.addRose();
  }

  addRose() {
    this.setValues();
    if (!this.values.length) {
      return;
    }
    this.setCoords();
    this.ctx = this.canvasNode.getContext(`2d`);
    this.setColor();
    this.drawRose();
  }

  drawRose() {
    const {north, northWest, west, southWest, south, southEast, east, northEast} = this.coords;
    const lineColor = this.color || `#000`
    const lineWitdh = this.canvasNode.dataset.lineWidth || 3

    this.ctx.strokeStyle = lineColor;
    this.ctx.lineWidth = parseInt(lineWitdh, 10);

    this.ctx.beginPath();
    this.ctx.moveTo(...north);
    this.ctx.lineTo(...northWest);
    this.ctx.lineTo(...west);
    this.ctx.lineTo(...southWest);
    this.ctx.lineTo(...south);
    this.ctx.lineTo(...southEast);
    this.ctx.lineTo(...east);
    this.ctx.lineTo(...northEast);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(...north);
    this.ctx.lineTo(...south);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(...west);
    this.ctx.lineTo(...east);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(...northWest);
    this.ctx.lineTo(...southEast);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(...southWest);
    this.ctx.lineTo(...northEast);
    this.ctx.stroke();
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvasNode.width, this.canvasNode.height);

    this.ctx = null;
    this.coords = null;

    this.values = [];
    this.color = null;
    this.maxValue = 0;
    this.xStep = 0;
    this.yStep = 0;
    this.diagonalStep = 0;
  }

  setCoords() {
    if (!this.values.length) {
      return;
    }
    this.maxValue = Math.max(...this.values);

    this.coords = {
      north: [this.width, this.getY(this.values[0], true)],
      northWest: this.getDiagonal(this.values[1], 2),
      west: [this.getX(this.values[2], true), this.height],
      southWest: this.getDiagonal(this.values[3], 1),
      south: [this.width, this.getY(this.values[4])],
      southEast: this.getDiagonal(this.values[5], 0),
      east: [this.getX(this.values[6]), this.height],
      northEast: this.getDiagonal(this.values[7], 3)
    }
  }

  setValues() {
    const days = this.canvasNode.dataset.days;
    if (days) {
      const values = days.replace(/,/g, ``);
      for (const symbol of values) {
        const number = parseInt(symbol, 10);
        if (typeof number === `number`) {
          this.values.push(number);
        }
      }
    }
  }

  setColor() {
    let windSpeed = this.canvasNode.dataset.wind;
    let color;
    if (windSpeed) {
      const wind = parseInt(windSpeed, 10);
      if (wind <= 5) {
        color = `#52D9FA`;
      } else if (wind <= 10) {
        color = `#A4E5E5`;
      } else if (wind <= 15) {
        color = `#D0EBD9`;
      } else if (wind <= 20) {
        color = `#ECE8C9`;
      } else if (wind <= 25) {
        color = `#E8B998`;
      } else if (wind <= 30) {
        color = `#E58C6A`;
      } else if (wind <= 35) {
        color = `#E15D39`;
      }
    }

    this.color = color || this.canvasNode.dataset.color;
  }

  getX(value, negative) {
    this.xStep = this.width / this.maxValue;
    let x;
    if (negative) {
      x = this.width - this.xStep * value + 2
    } else {
      x = this.width + this.xStep * value - 2
    }
    return x;
  }

  getY(value, negative) {
    this.yStep = this.height / this.maxValue;
    let y;
    if (negative) {
      y = this.height - this.yStep * value + 2
    } else {
      y = this.height + this.yStep * value - 2
    }
    return y;
  }

  getDiagonal(value, sectionIndex) {
    this.diagonalStep = this.diagonal / this.maxValue;
    const angle = Math.PI / 4 + (Math.PI / 2) * sectionIndex;
    const radius = this.diagonalStep * value;
    let x = this.width + radius * Math.cos(angle);
    let y = this.height + radius * Math.sin(angle);

    switch (sectionIndex) {
      case 0: x -= 2; y -= 2; break;
      case 1: x += 2; y -= 2; break;
      case 2: x += 2; y += 2; break;
      case 3: x -= 2; y += 2; break;
    }
    return [x, y];
  }
}
