// region Blocks
import load from './utils/load';
import header from '../blocks/header/header';
import siteNav from '../blocks/site-nav/site-nav';
import tabs from '../blocks/tabs/tabs';
import blur from '../blocks/blur/blur';
import combobox from '../blocks/combobox/combobox';
import inputFiles from '../blocks/input-file/input-file';
import form from '../blocks/form/form';
import parallax from '../blocks/parallax/parallax';
import accordion from '../blocks/accordion/accordion';
import menuCenter from '../blocks/menu-center/menu-center';
import shipInformation from '../blocks/ship-information/ship-information';
import mainPage from '../blocks/main-page/main-page';
import tabsSlider from '../blocks/tabs-slider/tabs-slider';
import sliderThumbs from '../blocks/slider-thumbs/slider-thumbs';
import partners from '../blocks/partners/partners';
import map from '../blocks/map/map';
import contacts from '../blocks/contacts/contacts';

// endregion

load();
header();
siteNav();
tabs();
blur();
map();
combobox();
inputFiles();
form();
parallax();
accordion();
menuCenter();
shipInformation();
mainPage();
tabsSlider();
sliderThumbs();
partners();
contacts();
