
/* eslint-disable */
import fslightbox from './libs/fslightbox.js';
import lazySizes from './libs/lazysizes.min.js';
import smoothscroll from 'smoothscroll-polyfill';

lazySizes.init();
smoothscroll.polyfill();

/* eslint-enable */
