export default () => {
  const containerElement = document.querySelector(`.main-page__fleet-container`);

  if (!containerElement) {
    return;
  }

  const panels = containerElement.querySelectorAll(`.tabs-slider__panel`);

  const initShip = () => {
    panels.forEach((panel) => {
      const slides = panel.querySelectorAll(`.ship-information__slider-item`);
      const mainImg = panel.querySelector(`.ship-information__main-img-wrapper`);

      let activeSlider = -1;
      slides.forEach((it, j) => {
        if (it.classList.contains(`ship-information__slider-item--active`)) {
          activeSlider = j;
        }

        it.addEventListener(`click`, (evt) => {
          evt.preventDefault();

          if (activeSlider >= 0) {
            slides[activeSlider].classList.remove(`ship-information__slider-item--active`);
          }

          it.classList.add(`ship-information__slider-item--active`);
          if (it.hasAttribute(`data-img1`) && it.hasAttribute(`data-img2`) && it.hasAttribute(`data-img3`) && it.hasAttribute(`data-img4`)) {
            const img1 = it.getAttribute(`data-img1`);
            const img2 = it.getAttribute(`data-img2`);
            const img3 = it.getAttribute(`data-img3`);
            const img4 = it.getAttribute(`data-img4`);
            mainImg.innerHTML = `<picture>
            <source type="image/webp" srcset="${img3} 1x, ${img4} 2x">
            <img src="${img1}" srcset="${img2} 2x" width="759" height="486" alt="Фотография корабля"></picture>`;
          } else if (it.hasAttribute(`data-img1`)) {
            const img1 = it.getAttribute(`data-img1`);
            mainImg.innerHTML = `<picture>
            <img src="${img1}" width="759" height="486" alt="Фотография корабля"></picture>`;
          }
          activeSlider = j;
        });

        it.addEventListener(`mousedown`, () => {
          mainImg.classList.remove(`ship-information__main-img--animate`);
        });
      });
    });
  };

  initShip();
  window.addEventListener(`resize`, () => {
    initShip();
  });
};
