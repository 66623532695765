import Swiper from '../../js/libs/swiper';
import initWindRoses from "../wind-rose/initWindRoses";

export default () => {
  const siteNavElement = document.querySelector(`.site-nav`);
  const mainElement = document.querySelector(`.template-page`);
  const sectionElement = document.querySelector(`.template-section`);
  const headerElement = document.querySelector(`.header`);
  const searchButtonWrap = document.querySelector(`.site-nav__search`);
  const bodyElement = document.querySelector(`body`);

  const itemElements = siteNavElement.querySelectorAll(`.site-nav__menu-item`);
  const menuCenterElement = siteNavElement.querySelector(`.menu-center`);
  const contentElements = siteNavElement.querySelectorAll(`.menu-center__menu-content`);
  const buttonClose = siteNavElement.querySelector(`.menu-center__close`);
  const mobileReturnButton = siteNavElement.querySelector(`.menu-center__mobile-return`);
  const leftElement = siteNavElement.querySelector(`.site-nav__wrapper-left`);
  const centerElement = siteNavElement.querySelector(`.site-nav__wrapper-center`);
  const rightElement = siteNavElement.querySelector(`.site-nav__wrapper-right`);
  const searchElement = siteNavElement.querySelector(`.site-nav__wrapper-search`);
  const searchButton = siteNavElement.querySelector(`.site-nav__search-button`);
  const closeSearch = siteNavElement.querySelector(`.menu-search__close`);
  const mobileMenuToggler = siteNavElement.querySelector(`.site-nav__mobile-menu`);

  if (headerElement.classList.contains(`header--eng`)) {
    return;
  }

  let isSearchOpened = false;
  let isMenuOpened = false;
  let isMobileMenuOPened = false;
  let lastIndex = -1;
  const lengthList = 9;

  let menuRightSwiper = null;
  const swiperEl = document.querySelector(`.menu-right__slider`);
  let y = window.pageYOffset;

  const createMenuRightSwiper = () => {
    if (!menuRightSwiper) {

      menuRightSwiper = new Swiper(swiperEl, {
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        loop: true,
        spaceBetween: 0,
        pagination: {
          el: `.menu-right__pagination`,
          clickable: true,
        },

      });

      initWindRoses();
    }
  };

  const onShowMobileMenu = () => {
    isMobileMenuOPened = !isMobileMenuOPened;
    siteNavElement.classList.toggle(`site-nav--opened`);

    if (isMobileMenuOPened) {
      y = window.pageYOffset;
      bodyElement.classList.add(`modal-opened`);
    } else {
      bodyElement.classList.remove(`modal-opened`);
      window.scrollTo({
        top: y,
        behavior: "instant"
      });
    }
  };

  mobileMenuToggler.addEventListener(`click`, onShowMobileMenu);

  const onClickMenuLink = (it, i, currentElementName) => {
    isMenuOpened = true;
    const currentElement = document.querySelector(`.${currentElementName}`);
    makeDisableButtonSearch(currentElementName);
    isSearchOpened = false;
    
    centerElement.classList.remove(`site-nav__wrapper-center--hidden`);
    rightElement.classList.remove(`site-nav__wrapper-right--hidden`);
    createMenuRightSwiper();

    if (lastIndex >= 0) {
      itemElements[lastIndex].classList.remove(`site-nav__menu-item--active`);
      contentElements[lastIndex].classList.remove(`menu-center__menu-content--visible`);
    }

    if (window.matchMedia(`(max-width: 1023px)`).matches === true) {
      //let header = it.querySelector(`a`).innerHTML;
      //menuCenterElement.querySelector(`.menu-center__mobile-heading p`).innerHTML = header;
      leftElement.classList.add(`site-nav__wrapper-left--hidden`);
    } else {

      if (lastIndex < 0) {
        currentElement.classList.add(`${currentElementName}--animate-menu`);
      }
    }

    it.classList.add(`site-nav__menu-item--active`);
    contentElements[i].classList.add(`menu-center__menu-content--visible`);
    lastIndex = i;
    setTimeout(() => {
      y = window.pageYOffset;
      bodyElement.classList.add(`modal-opened`);
    }, 500);
  };

  const onCloseMenu = (currentElementName) => {
    isMenuOpened = false;
    const currentElement = document.querySelector(`.${currentElementName}`);
    currentElement.classList.add(`${currentElementName}--animate-menu-reverse`);
    if (lastIndex >= 0) {
      itemElements[lastIndex].classList.remove(`site-nav__menu-item--active`);
    }
    bodyElement.classList.remove(`modal-opened`);

    setTimeout(() => {
      currentElement.classList.remove(`${currentElementName}--animate-menu`);
      currentElement.classList.remove(`${currentElementName}--animate-menu-reverse`);
      contentElements[lastIndex].classList.remove(`menu-center__menu-content--visible`);
      lastIndex = -1;
      centerElement.classList.add(`site-nav__wrapper-center--hidden`);
      rightElement.classList.add(`site-nav__wrapper-right--hidden`);
    }, 500);
  };

  const onCloseSearch = (currentElementName) => {
    const currentElement = document.querySelector(`.${currentElementName}`);
    isSearchOpened = false;
    isMenuOpened = false;
    makeDisableButtonSearch(currentElementName);
    lastIndex = -1;
    bodyElement.classList.remove(`modal-opened`);
    currentElement.classList.add(`${currentElementName}--animate-menu-reverse`);

    setTimeout(() => {
      currentElement.classList.remove(`${currentElementName}--search-background`);
      currentElement.classList.remove(`${currentElementName}--animate-menu`);
      currentElement.classList.remove(`${currentElementName}--animate-menu-reverse`);
    }, 500);
  };

  if (mainElement) {
    // Если мы на главной странице
    itemElements.forEach((it, i) => {
      if (i > lengthList - 1) {
        return;
      }

      it.addEventListener(`click`, (evt) => {
        evt.preventDefault();
        onClickMenuLink(it, i, `template-page`);
      });
    });

    buttonClose.addEventListener(`click`, () => {
      onCloseMenu(`template-page`);
    });
  }

  if (sectionElement) {

    // Если мы на внутренней странице
    itemElements.forEach((it, i) => {
      if (i > lengthList - 1) {
        return;
      }

      it.addEventListener(`click`, (evt) => {
        evt.preventDefault();
        onClickMenuLink(it, i, `template-section`);
      });
    });

    buttonClose.addEventListener(`click`, () => {
      onCloseMenu(`template-section`);
    });
  }

  mobileReturnButton.addEventListener(`click`, () => {
    centerElement.classList.add(`site-nav__wrapper-center--hidden`);
    leftElement.classList.remove(`site-nav__wrapper-left--hidden`);

    if (lastIndex >= 0) {
      itemElements[lastIndex].classList.remove(`site-nav__menu-item--active`);
      contentElements[lastIndex].classList.remove(`menu-center__menu-content--visible`);
    }
  });

  // Заготовка для поиска Десктоп
  const makeActiveButtonSearch = () => {
    searchElement.classList.remove(`site-nav__wrapper-search--hidden`);
    searchButtonWrap.classList.add(`site-nav__search--active`);
  };

  const makeDisableButtonSearch = (currentElementName) => {
    const currentElement = document.querySelector(`.${currentElementName}`);
    searchElement.classList.add(`site-nav__wrapper-search--hidden`);
    searchButtonWrap.classList.remove(`site-nav__search--active`);
    if (isMenuOpened) {
      currentElement.classList.remove(`${currentElementName}--search-background`);
    }
  };

  const closeSearchSectionDesktop = () => {
    if (mainElement) {
      onCloseSearch(`template-page`);
    }
    if (sectionElement) {
      onCloseSearch(`template-section`);
    }
  };

  const onOpenSearch = (currentElementName) => {
    const currentElement = document.querySelector(`.${currentElementName}`);
    isSearchOpened = true;
    isMenuOpened = true;
    currentElement.classList.add(`${currentElementName}--animate-menu`);
    currentElement.classList.add(`${currentElementName}--search-background`);
    centerElement.classList.add(`site-nav__wrapper-center--hidden`);
    rightElement.classList.add(`site-nav__wrapper-right--hidden`);
    searchElement.classList.remove(`site-nav__wrapper-search--hidden`);
  };

  const showSearchSectionDesktop = () => {
    setTimeout(() => {
      bodyElement.classList.add(`modal-opened`);
    }, 500);

    if (mainElement) {
      onOpenSearch(`template-page`);
    }

    if (sectionElement) {
      onOpenSearch(`template-section`);
    }
  };

  const toggleSearchSectionDesktop = () => {
    makeActiveButtonSearch();
    if (!isSearchOpened) {
      showSearchSectionDesktop();
    } else {
      closeSearchSectionDesktop();
    }
  };

  const showSearchSectionMobile = () => {
    searchElement.classList.remove(`site-nav__wrapper-search--hidden`);
    leftElement.classList.add(`site-nav__wrapper-left--hidden`);
    bodyElement.classList.add(`modal-opened`);
  };

  const closeSearchSectionMobile = () => {
    searchElement.classList.add(`site-nav__wrapper-search--hidden`);
    leftElement.classList.remove(`site-nav__wrapper-left--hidden`);
  };

  const closeSearchSection = () => {
    if (window.matchMedia(`(max-width: 1023px)`).matches === true) {
      closeSearchSectionMobile();
    } else {
      closeSearchSectionDesktop();
    }
  };

  if (searchButton) {
    searchButton.addEventListener(`click`, (evt) => {
      evt.preventDefault();
      if (lastIndex >= 0) {
        itemElements[lastIndex].classList.remove(`site-nav__menu-item--active`);
        contentElements[lastIndex].classList.remove(`menu-center__menu-content--visible`);
      }

      if (window.matchMedia(`(max-width: 1023px)`).matches === false) {
        toggleSearchSectionDesktop();
      }
      if (window.matchMedia(`(max-width: 1023px)`).matches === true) {
        showSearchSectionMobile();
      }
    });
  }

  if (closeSearch) {
    closeSearch.addEventListener(`click`, () => {
      closeSearchSection();
    });
  }

  // Переход меню для Edge 18
  if ((mainElement) && (navigator.userAgent.search(/Edge/) > 0)) {
    let footerElement = document.querySelector(`footer`);
    let navLinks = document.querySelectorAll(`.site-nav__wrapper-left a`);

    window.addEventListener(`scroll`, () => {
      navLinks.forEach((it) => {
        let currentScroll = window.pageYOffset;
        let topItem = it.getBoundingClientRect().top + currentScroll;
        let topFooter = footerElement.getBoundingClientRect().top + currentScroll;
        if ((topItem > window.innerHeight - 5) && (topItem < topFooter + 5)) {
          it.classList.add(`site-nav__link-inverted`);
        } else {
          it.classList.remove(`site-nav__link-inverted`);
        }
      });
    });
  }
};

