export default () => {
  const inputElement = document.querySelector(`.menu-center__subscription-input`);
  const buttonElement = document.querySelector(`.menu-center__subscription-button`);
  const menuFormElement = document.querySelector(`.menu-center__form`);

  if (!menuFormElement) {
    return;
  }

  let isInputValid = (value) => {
    if ((value.indexOf(`@`) > -1) && (value.length - value.indexOf(`@`) > 4) && (value.indexOf(`.`) > -1) && (value.length - value.indexOf(`.`) > 2)) {
      return true;
    }
    return false;
  };

  inputElement.addEventListener(`input`, (evt) => {
    const target = evt.target;
    const value = target.value.replace(/\s+/g, ` `).trim().toLowerCase();
    if (isInputValid(value)) {
      menuFormElement.classList.remove(`menu-center__form--error`);
      menuFormElement.classList.add(`menu-center__form--valid`);
    } else {
      menuFormElement.classList.add(`menu-center__form--error`);
      menuFormElement.classList.remove(`menu-center__form--valid`);
      menuFormElement.classList.remove(`menu-center__form--success`);
    }
  });

  buttonElement.addEventListener(`click`, () => {
    const value = inputElement.value.replace(/\s+/g, ` `).trim().toLowerCase();
    if (!isInputValid(value)) {
      if (window.matchMedia(`(max-width: 768px)`).matches === true) {
        menuFormElement.classList.add(`menu-center__form--error`);
      }

      menuFormElement.classList.remove(`menu-center__form--success`);
      menuFormElement.classList.remove(`menu-center__form--valid`);
    } else {
      menuFormElement.classList.add(`menu-center__form--success`);
    }
  });
};
