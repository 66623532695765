import GoogleMapsApi from '../map/google-map-api';


export default () => {
  const mapElement = document.querySelector(`#map-institute`);

  if (mapElement) {
    const gApiKey = `AIzaSyAOHzjK9PJnt76_lPcJ8uQWES7xQRd89qs`;
    const gmapApi = new GoogleMapsApi(gApiKey);

    gmapApi.load().then(() => {
      renderMap();
    });

    const renderMap = () => {
      const position = {
        lat: 59.946139,
        lng: 30.233232
      };
      const mapParams = {
        center: {
          lat: 59.946,
          lng: position.lng + (position.lng * 0.0006)
        },
        zoom: 11.8,
        minZoom: 2,
        maxZoom: 100
      };

      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(mapElement, mapParams);
      // eslint-disable-next-line no-undef, no-unused-vars
      const marker = new google.maps.Marker({
        position,
        map,
        title: `Арктический и антарктический научно-исследовательский институт`
      });
    };
  }
};
