import Swiper from '../../js/libs/swiper';

export default () => {
  const initSliderThumb = (sliderBlock) => {
    if (sliderBlock.querySelector(`.gallery-thumbs`) && sliderBlock.querySelector(`.gallery-top`)) {
      const galleryThumbsBlock = sliderBlock.querySelector(`.gallery-thumbs`);
      const galleryTopBlock = sliderBlock.querySelector(`.gallery-top`);
      const galleryThumbsSlidesNumber = sliderBlock.querySelectorAll(`.gallery-thumbs .swiper-slide`).length;
      const galleryTopSlidesNumber = sliderBlock.querySelectorAll(`.gallery-top .swiper-slide`).length;
      const galleryThumbs = new Swiper(galleryThumbsBlock, {
        spaceBetween: 11,
        slidesPerView: `auto`,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        // loop: true,
        // loopedSlides: galleryThumbsSlidesNumber,
        // centeredSlides: true,
        // slideToClickedSlide: true,
        breakpoints: {
          768: {
            spaceBetween: 15,
          },
        },
      });

      const galleryTop = new Swiper(galleryTopBlock, {
        slidesPerView: 1.1,
        spaceBetween: 0,
        slideToClickedSlide: true,
        // centeredSlides: true,
        loopedSlides: galleryTopSlidesNumber,
        loop: true,
        navigation: {
          nextEl: sliderBlock.querySelector(`.slider-thumbs__next`),
          prevEl: sliderBlock.querySelector(`.slider-thumbs__prev`),
        },
        thumbs: {
          swiper: galleryThumbs
        },
        breakpoints: {
          768: {
            slidesPerView: 1.15,
          },
        },
      });

      // galleryTop.controller.control = galleryThumbs;
      // galleryThumbs.controller.control = galleryTop;

      const nextBtn = galleryTop.navigation.nextEl;
      const prevBtn = galleryTop.navigation.prevEl;

      nextBtn.addEventListener(`mouseover`, () => {
        sliderBlock.classList.add(`slider-thumbs--next-hover`);
      });

      nextBtn.addEventListener(`mouseout`, () => {
        sliderBlock.classList.remove(`slider-thumbs--next-hover`);
      });

      prevBtn.addEventListener(`mouseover`, () => {
        sliderBlock.classList.add(`slider-thumbs--prev-hover`);
      });

      prevBtn.addEventListener(`mouseout`, () => {
        sliderBlock.classList.remove(`slider-thumbs--prev-hover`);
      });
    }
  };

  const sliderThumbs = document.querySelectorAll(`.slider-thumbs`);
  sliderThumbs.forEach((slider) => {
    initSliderThumb(slider);
  });
};
