export default () => {
  const MAX_WIDTH_MOBILE = `767px`;

  function TabWidget(el, selectedIndex) {
    if (!el) {
      return;
    }

    this.el = el;
    this.tabTriggers = this.el.getElementsByClassName(`tabs__trigger-link`);
    this.tabPanels = this.el.getElementsByClassName(`tabs__panel`);
    this.titlePrefix = document.getElementById(`title-prefix`);

    if (this.tabTriggers.length === 0 || this.tabTriggers.length !== this.tabPanels.length) {
      return;
    }

    this._init(selectedIndex);
  }

  TabWidget.prototype._init = function (selectedIndex) {
    this.tabTriggersLength = this.tabTriggers.length;
    this.selectedTab = 0;
    this.prevSelectedTab = null;
    this.clickListener = this._clickEvent.bind(this);
    this.keydownListener = this._keydownEvent.bind(this);
    this.keys = {
      prev: 37,
      next: 39,
    };

    for (let i = 0; i < this.tabTriggersLength; i++) {
      this.tabTriggers[i].index = i;
      this.tabTriggers[i].addEventListener(`click`, this.clickListener, false);
      this.tabTriggers[i].addEventListener(`keydown`, this.keydownListener, false);

      if (this.tabTriggers[i].classList.contains(`is-selected`)) {
        this.selectedTab = i;
      }
    }

    if (!isNaN(selectedIndex)) {
      this.selectedTab = selectedIndex < this.tabTriggersLength ? selectedIndex : this.tabTriggersLength - 1;
    }

    this.selectTab(this.selectedTab);
    this.el.classList.add(`is-initialized`);
  };

  TabWidget.prototype._clickEvent = function (evt) {
    evt.preventDefault();

    if (evt.target.index === this.selectedTab) {
      return;
    }

    this.selectTab(evt.target.index, true);

    if (window.matchMedia(`(max-width: ${MAX_WIDTH_MOBILE})`).matches) {
      evt.target.parentNode.scrollIntoView({block: `nearest`, inline: `center`});
    }
  };

  TabWidget.prototype._keydownEvent = function (evt) {
    let targetIndex;

    if (evt.keyCode === this.keys.prev || evt.keyCode === this.keys.next) {
      evt.preventDefault();
    } else {
      return;
    }

    if (evt.keyCode === this.keys.prev && evt.target.index > 0) {
      targetIndex = evt.target.index - 1;
    } else if (evt.keyCode === this.keys.next && evt.target.index < this.tabTriggersLength - 1) {
      targetIndex = evt.target.index + 1;
    } else {
      return;
    }

    this.selectTab(targetIndex, true);
  };

  TabWidget.prototype._show = function (index, userInvoked) {
    this.tabTriggers[index].classList.add(`is-selected`);
    this.tabTriggers[index].setAttribute(`aria-selected`, true);
    this.tabTriggers[index].setAttribute(`tabindex`, 0);

    if (this.titlePrefix) {
      this.titlePrefix.textContent = this.tabTriggers[index].textContent;
    }

    this.tabPanels[index].classList.remove(`is-hidden`);
    this.tabPanels[index].setAttribute(`aria-hidden`, false);

    if (userInvoked) {
      this.tabTriggers[index].focus();
    }
  };

  TabWidget.prototype._hide = function (index) {
    this.tabTriggers[index].classList.remove(`is-selected`);
    this.tabTriggers[index].setAttribute(`aria-selected`, false);
    this.tabTriggers[index].setAttribute(`tabindex`, -1);

    this.tabPanels[index].classList.add(`is-hidden`);
    this.tabPanels[index].setAttribute(`aria-hidden`, true);
  };

  TabWidget.prototype.selectTab = function (index, userInvoked) {
    if (this.prevSelectedTab === null) {
      for (let i = 0; i < this.tabTriggersLength; i++) {
        if (i !== index) {
          this._hide(i);
        }
      }
    } else {
      this._hide(this.selectedTab);
    }

    this.prevSelectedTab = this.selectedTab;
    this.selectedTab = index;

    this._show(this.selectedTab, userInvoked);
  };

  TabWidget.prototype.destroy = function () {
    for (let i = 0; i < this.tabTriggersLength; i++) {
      this.tabTriggers[i].classList.remove(`is-selected`);
      this.tabTriggers[i].removeAttribute(`aria-selected`);
      this.tabTriggers[i].removeAttribute(`tabindex`);

      this.tabPanels[i].classList.remove(`is-hidden`);
      this.tabPanels[i].removeAttribute(`aria-hidden`);
      this.tabPanels[i].removeAttribute(`tabindex`);

      this.tabTriggers[i].removeEventListener(`click`, this.clickListener, false);
      this.tabTriggers[i].removeEventListener(`keydown`, this.keydownListener, false);

      delete this.tabTriggers[i].index;
    }

    this.el.classList.remove(`is-initialized`);
  };

  new TabWidget(document.getElementsByClassName(`tabs`)[0]);
};
