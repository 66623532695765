import simpleParallax from 'simple-parallax-js';

export default () => {
  const parallaxItems = document.querySelectorAll('.parallax');

  parallaxItems.forEach((el) => {
    const parallaxImage = el.querySelector('img');

    if ((parallaxImage)&&(!window.matchMedia(`(max-width: 767px)`).matches)) {
      new simpleParallax(parallaxImage);
    }
  });
};
