export default () => {
  const siteNavElement = document.querySelector(`.site-nav`);
  const intro = document.querySelector(`.intro`);
  let vh = window.innerHeight * 0.01;

  if (siteNavElement.classList.contains(`site-nav--eng`)) {
    return;
  }

  const setHeight = () => {
    vh = window.innerHeight * 0.01;

    siteNavElement.style.setProperty(`--vh`, `${vh}px`);
    if (intro) {
      intro.style.setProperty(`--vh`, `${vh}px`);
    }
  };

  window.addEventListener(`load`, () => {
    setHeight();
  });
};
